import { useEffect } from 'react';
import Box from '@mui/material/Box';
import TypographyMui5 from '@mui/material/Typography';
import StaticPage from 'components/organisms/StaticPage';
import LinkMui5 from '../../components/atoms/Link';
import { SEO_404_PAGE } from 'utils/seo';
import { datadogRum } from '@datadog/browser-rum';
import NextLinkMui5 from '../../components/atoms/NextLink';

export const Custom404Container = () => {
  useEffect(() => {
    const error = new Error('404 error. This page does not exist.');
    datadogRum.addError(error, {
      pageStatus: '404',
    });
  }, []);

  return (
    <StaticPage seo={SEO_404_PAGE}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        alignContent={'center'}
        minHeight={540}
      >
        <TypographyMui5 variant='h1'>404</TypographyMui5>
        <TypographyMui5>Oooops... This page does not exist</TypographyMui5>
        <TypographyMui5>
          <LinkMui5 href='/' component={NextLinkMui5}>
            Return to the home page
          </LinkMui5>
        </TypographyMui5>
      </Box>
    </StaticPage>
  );
};
